//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import DropdownSelect from '~/components/UI/Dropdown/DropdownSelect.vue'
import ModalOverlay from '~/components/UI/Modal/ModalOverlay.vue'
import Toggler from '~/components/UI/Toggler.vue'
import Counter from '~/components/UI/Counter.vue'
import Table from '~/components/UI/Table.vue'
import uploadImage from '~/mixins/uploadImage'
import validationWatcher from '~/mixins/validationWatcher'
import getCode from '~/mixins/getCode'
import getDeviceType from '~/mixins/getDeviceType'
const wrongFormat = helpers.regex('text', /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\-'\s]*$/)
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\s]*$/)
export default {
  name: 'ToRefund',
  components: { Counter, Table, DropdownSelect, Toggler, ModalOverlay },
  mixins: [validationMixin, uploadImage, validationWatcher, getCode, getDeviceType],
  layout: 'profile',
  middleware: ['auth'],
  async asyncData({ $api, route, store }) {
    try {
      const response = await $api.orders.getOrder(encodeURIComponent(encodeURIComponent(route.params.order)))
      const order = response.data
      const form = {
        first_name: order.buyer.first_name,
        second_name: order.buyer.second_name,
        email: order.buyer.email,
        phone: order.buyer.phone,
        code: order.buyer.code,
        voivodeship: order.buyer.voivodeship,
        city: order.buyer.city,
        street: order.buyer.street,
        building: order.buyer.building,
        apartment: order.buyer.apartment,
      }
      const refundProducts = store.state.refund.products_to_refund.map((item, key) => ({
        ...item,
        id: key,
        reasonProductId: key,
        error: null,
        main_info: {
          files: item.files,
          name: item.name,
          set_name: item.set_name,
        },
      }))
      return {
        order,
        form,
        refundProducts,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      refundProducts: null,
      comment: '',
      receiver_another: false,
      file_id: null,
      form: {
        expectations: '',
        first_name: '',
        second_name: '',
        email: '',
        phone: '',
        code: '',
        voivodeship: '',
        city: '',
        street: '',
        building: '',
        apartment: '',
      },
      reasonError: false,
      selectNextStep: false,
      statuses: [
        // { key: 1, type: this.$t('keyproductDamage')},
        // { key: 2, type: this.$t('packingDamageOrIncompleteSet')},
        // { key: 3, type: this.$t('poorlyDeliveredProduct')},
        // { key: 4, type: this.$t('missingInstructionOrAssemblyProblem')},
        // { key: 5, type: this.$t('dontLikeProduct')},

        { key: 1, type: 'Uszkodzony towar'},
        { key: 2, type: 'Uszkodzone opakowanie lub niekompletne zamówienie'},
        { key: 3, type: 'Zamówienia nie dostarczono lub błędnie dostarczono'},
        { key: 4, type: 'Brak instrukcji lub problem ze złożeniem'},
        { key: 5, type: 'Produkt nie spełnia oczekiwań'},
      ],
      customerExpectations: [
        // { key: 1, type: this.$t('repairmentReplacement')},
        // { key: 2, type: this.$t('compensation')},
        // { key: 3, type: this.$t('return')},

        { key: 1, type: 'Naprawa/wymiana'},
        { key: 2, type: 'Odszkodowanie'},
        { key: 3, type: 'Zwrócić'},
      ],
    }
  },
  validations() {
    const rules = {
      form: {
        building: { required, maxLength: maxLength(10) },
        apartment: { maxLength: maxLength(10) },
        city: { required, minLength: minLength(2), maxLength: maxLength(100) },
        code: { required },
        first_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        street: { required, minLength: minLength(2), maxLength: maxLength(100) },
        voivodeship: { required, minLength: minLength(2), maxLength: maxLength(100) },
        email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
        phone: { required, minLength: minLength(10) },
        expectations: { required },
      },
    }

    if(this.receiver_another) {
      rules.form.second_name = { required }
    }

    return rules
  },
  computed: {
    fields() {
      return [
        {
          key: 'main_info',
          label: this.$t('product'),
          width: this.isMobile ? 'auto' : this.isTablet ? '260px' : '305px',
        },
        {
          key: 'id',
          label: this.$t('qty'),
          width: this.isMobile ? '120px' : this.isTablet ? '120px' : '139px',
        },
        {
          key: 'reasonProductId',
          label: this.$t('reason'),
          width: this.isMobile ? 'auto' : this.isTablet ? '260px' : '360px',
        },
        {
          key: 'refundSum',
          label: this.$t('totalPrice'),
          width: 'auto',
        },
      ]
    },
  },
  watch: {
    // 'form.code': {
    //   handler() {
    //     this.getCode()
    //   },
    // },
    refundProducts: {
      deep: true,
      handler(value) {
        if(this.reasonError) {
          value.forEach((item, key) => {
            if (item.reason !== '' ) {
              this.refundProducts[key].error = null
              this.disabled = false
            }
          })
          this.getReasonError()
        }
      },
    },
    receiver_another(value) {
      if(value) {
        this.clearForm()
        this.$v.form.$reset()
      } else {
        this.form.first_name = this.order.buyer.first_name
        this.form.second_name = this.order.buyer.second_name
        this.form.email = this.order.buyer.email
        this.form.phone = this.order.buyer.phone
        this.form.code = this.order.buyer.code
        this.form.voivodeship = this.order.buyer.voivodeship
        this.form.city = this.order.buyer.city
        this.form.street = this.order.buyer.street
        this.form.building = this.order.buyer.building
        this.form.apartment = this.order.buyer.apartment
      }
    },
  },
  methods: {
    nextStep() {
      this.checkProductsReason()
      this.getReasonError()
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      if (this.reasonError) {
        this.loading = false
        return
      }
      this.selectNextStep = !this.selectNextStep
      this.setValidationSettings(false)
    },
    decreaseItemRefund(i) {
      const setName = this.refundProducts[i].set_name
      if(setName) {
        this.refundProducts.forEach((item, key) => {
          if (item.set_name === setName) {
            this.refundProducts[key].to_refund--
            this.refundProducts[key].refundSum = this.refundProducts[key].refundSum - this.refundProducts[key].price_brutto
          }
        })
      } else {
        this.refundProducts[i].to_refund--
        this.refundProducts[i].refundSum = this.refundProducts[i].refundSum - this.refundProducts[i].price_brutto
      }
    },
    increaseItemRefund(i) {
      const setName = this.refundProducts[i].set_name
      if(this.refundProducts[i].to_refund < this.refundProducts[i].quantity) {
        if(setName) {
          this.refundProducts.forEach((item, key) => {
            if (item.set_name === setName) {
              this.refundProducts[key].to_refund++
              this.refundProducts[key].refundSum = this.refundProducts[key].to_refund * this.refundProducts[key].price_brutto
            }
          })
        } else {
          this.refundProducts[i].to_refund++
          this.refundProducts[i].refundSum = this.refundProducts[i].to_refund * this.refundProducts[i].price_brutto
        }
      }
    },
    selectReason(value, i) {
      this.refundProducts[i].reason = value
    },
    clearForm() {
      this.form.first_name = ''
      this.form.second_name = ''
      this.form.email = ''
      this.form.phone = ''
      this.form.code = ''
      this.form.voivodeship = ''
      this.form.city = ''
      this.form.street = ''
      this.form.building = ''
      this.form.apartment = ''
    },
    createRefund() {
      this.checkProductsReason()
      this.getReasonError()
      this.setValidationSettings(true)
      if (!this.checkForm()) return
      if (this.reasonError) {
        this.loading = false
        return
      }
      const refundProducts = this.refundProducts.filter(item => item.to_refund !== 0)
      const products = refundProducts.map((item) => ({
        slug: item.slug,
        quantity: item.to_refund,
        reason: item.reason.key,
      }))

      const orderId = this.$route.params.order
      const payload = {
        status: 1,
        expectations: this.form.expectations.key,
        file_id: this.file_id,
        comment: this.comment,
        receiver_another: this.receiver_another,
        receiver: {
          first_name: this.form.first_name,
          second_name: this.form.second_name,
          email: this.form.email,
          phone: this.form.phone.replace(/[^+0-9]/g, ''),
          code: this.form.code,
          building: this.form.building,
          apartment: this.form.apartment,
          street: this.form.street,
          city: this.form.city,
          voivodeship: this.form.voivodeship,
        },
        products,
      }
      for (const propName in payload) {
        if (payload[propName] === '' || payload[propName] === null) {
          delete payload[propName]
        }
      }
      try {
        this.$api.refunds.createRefundFromOrder(encodeURIComponent(encodeURIComponent(orderId)), payload)
        this.setValidationSettings(false)
        this.$router.push({ path: '/profile/orders' })
      } catch (error) {
        // console.log(error)
      }
    },
    checkProductsReason() {
      this.refundProducts.forEach((item, key) => {
        if (item.reason === '' ) {
          this.refundProducts[key].error = { message: 'The Reason field is required' }
        } else {
          this.refundProducts[key].error = null
        }
      })
    },
    getReasonError() {
      this.reasonError = this.refundProducts.some(item => item.error !== null)
    },
  },
}

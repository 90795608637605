//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Table from '~/components/UI/Table.vue'
import Paginations from '~/components/UI/Paginations.vue'
import getDeviceType from '~/mixins/getDeviceType'
export default {
  components: {
    Table,
    Paginations,
  },
  mixins: [getDeviceType],
  layout: 'profile',
  middleware: ['auth'],
  async asyncData({ $api }) {
    const payload = {
      per_page: 10,
      page: 1,
    }
    try {
      const response = await $api.refunds.getRefunds(payload)
      const { data, meta } = response
      return {
        refunds: data,
        total: meta.pagination.total,
        pages: meta.pagination.total_pages,
        countItems: meta.pagination.count,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      statuses: [this.$t('pending'), this.$t('accepted'), this.$t('sentToStock'), this.$t('arrivedToStock'), this.$t('returnedToClient'), this.$t('denied'), this.$t('refunded')],
      refunds: [],
      total: 0,
      pages: 0,
      sort: {
        orderBy: {},
        per_page: 10,
        page: 1,
      },
      selectPage: 1,
      countItems: 0,
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'number',
          label: this.$t('refundNumber'),
          sortable: true,
          width: '124px',
        },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
          width: this.isLaptop ? 'auto' : '136px',
        },
        {
          key: 'created_at',
          label: this.$t('refundDate'),
          sortable: true,
          width: this.isLaptop ? 'auto' : '127px',
        },
        {
          key: 'order_number',
          label: this.$t('orderNumber'),
          sortable: true,
          width: this.isLaptop ? 'auto' : '183px',
        },
        {
          key: 'total_amount',
          label: this.$t('refundSum'),
          sortable: true,
          width: this.isLaptop ? 'auto' : '183px',
        },
        {
          key: 'user',
          label: this.$t('user'),
          sortable: true,
          width: this.isLaptop ? 'auto' : '184px',
        },
        {
          key: 'action',
          label: this.$t('action'),
          sortable: true,
          width: this.isLaptop ? 'auto' : '175px',
        },
      ]
    },
  },
  methods: {
    async fetchRefunds() {
      const payload = {
        orderBy: this.sort.orderBy,
        per_page: this.sort.per_page,
        page: this.sort.page,
      }
      try {
        const response = await this.$api.refunds.getRefunds(payload)
        const { data, meta } = response
        this.refunds = data
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      } catch (error) {
        // console.log(error)
      }
    },
    onSort(value) {
      this.sort.orderBy = {
        [value.key]:
          this.sort.orderBy[value.key] &&
          this.sort.orderBy[value.key] === 'DESC'
            ? 'ASC'
            : 'DESC',
      }
      this.fetchRefunds()
    },
    onClickTableItem(value) {
      this.$router.push({ path: `/profile/refunds/${value.number}` })
    },
    selectOffset(value) {
      this.sort.per_page = value
    },

    onSelectPage(value) {
      this.sort.page = value
      this.fetchRefunds()
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import Counter from '~/components/UI/Counter.vue'
import Paginations from '~/components/UI/Paginations.vue'
import ProductPrice from '~/components/UI/ProductPrice.vue'
import { IS_MOBILE, IS_LAPTOP } from '~/store/device/type.device'
export default {
  components: {
    ProductPrice,
    Paginations,
    Counter,
  },
  layout: 'profile',
  middleware: ['auth'],
  async asyncData({ $api, store }) {
    const payload = {
      per_page: await store.getters['device/IS_MOBILE'] ? 3 : 10,
      page: 1,
    }
    try {
      const responseFavorites = await $api.favorites.getFavorites(payload)
      const { data, meta } = responseFavorites
      const filterFavorites = data.map((item) => {
        return {
          ...item,
          cart_quantity: 1,
          count: item.cart_quantity,
        }
      })
      return {
        favorites: filterFavorites,
        total: meta.pagination.total,
        pages: meta.pagination.total_pages,
        countItems: meta.pagination.count,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      favorites: [],
      total: 0,
      pages: 0,
      count: 1,
      disabledDec: false,
      disabledInc: false,
      selectProduct: '',
      textTicket: '',
      sort: {
        per_page: this.isMobile ? 3 : 10,
        page: 1,
      },
      selectPage: 1,
      countItems: 0,
    }
  },
  computed: {
    ...mapState({
      settings: state => state.settings.settings,
    }),
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
    isLaptop() {
      return this.$store.getters[`device/${IS_LAPTOP}`]
    },
  },
  watch: {
    textTicket() {
      setTimeout(() => {
        this.textTicket = ''
      }, 4000)
    },
  },
  methods: {
    async fetchFavorites() {
      const payload = {
        per_page: this.sort.per_page,
        page: this.sort.page,
      }
      try {
        const response = await this.$api.favorites.getFavorites(payload)
        const { data, meta } = response
        this.favorites = data
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      } catch (error) {
        // console.log(error)
      }
    },
    increaseItem(index) {
      this.favorites[index].cart_quantity++
    },
    decreaseItemCart(index) {
      this.favorites[index].cart_quantity--
    },
    async addProductToCart(product, entity, index) {
      const form = {
        entity,
        slug: product.item.slug,
        quantity: product.cart_quantity,
      }
      const productCart = {
        name: product.item.name,
        picture: product.item.files[0].url,
        slug: product.item.slug,
        price: product.item.price_type.netto,
        qty: product.item.cart_quantity,
      }

      try {
        await this.$api.cart.addItemCart(form)
        this.$store.commit('cart/SET_CART_PRODUCT', productCart)
        this.favorites[index].count = product.cart_quantity
      } catch (error) {
        // console.log(error)
      }
    },
    async createTicket(product, typeTicket) {
      this.selectProduct = product.item.slug
      const params = {
        type: typeTicket,
        code: product.item.code,
        slug: product.item.slug,
        entity: product.entity,
      }

      try {
        await this.$api.tickets.addTicket(params)
        this.textTicket = this.$t('yourRequestHasBeenSent')
      } catch (error) {}
    },
    selectOffset(value) {
      this.sort.per_page = value
    },
    onSelectPage(value) {
      this.sort.page = value
      this.fetchFavorites()
    },
    async deleteFavorite(id) {
      try {
        await this.$api.favorites.deleteFavorites(id)
        this.fetchFavorites()
      } catch (error) {
        // console.log(error)
      }
    },
    notifyMeWhenAvailableWisible(product) {
      if(product.item.price_type.brutto) {
        return Number(product.item.price_type.brutto) !== 0 && product.item.status === 2
      } else {
        return Number(product.item.price_type.netto) !== 0 && product.item.status === 2
      }
    },
    preorderWisible(product) {
      if(product.item.price_type.brutto) {
        return Number(product.item.price_type.brutto) !== 0 && product.item.status === 3
      } else {
        return Number(product.item.price_type.netto) !== 0 && product.item.status === 3
      }
    },
    requestThePriceWisible(product) {
      if(product.item.price_type.brutto) {
        return Number(product.item.price_type.brutto) === 0
      } else {
        return Number(product.item.price_type.netto) === 0
      }
    },
  },
}

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-users"},[_c('h1',{staticClass:"title headline size-profile fw-700"},[_vm._v(_vm._s(_vm.$t('tickets')))]),_vm._v(" "),_c('btn',{staticClass:"add-btn",attrs:{"width":_vm.isMobile ? '100%' : '136px'},on:{"click":_vm.createTicket}},[_c('icon',{staticClass:"icon",attrs:{"icon":"plus","stroke":_vm.$color.white}}),_vm._v("\n    "+_vm._s(_vm.$t('addTicket'))+"\n  ")],1),_vm._v(" "),_c('Table',{staticClass:"company-users__table",attrs:{"fields":_vm.fields,"items":_vm.tickets,"table-black":true,"vertical-padding-body-column":'12.5px'},on:{"click:head-item":_vm.onSort,"click:body-item":function($event){return _vm.onClickTableItem($event)}},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
var data = ref.data;
return [_c('span',{class:[
          'status pointer',
          ("status--" + data)
        ]},[_vm._v("\n        "+_vm._s(_vm.ticketStatusesCalendar[data])+"\n      ")])]}},{key:"cell(type)",fn:function(ref){
        var data = ref.data;
return [_vm._v("\n      "+_vm._s(_vm.getTypeTicket(data))+"\n    ")]}},{key:"cell(created_at)",fn:function(ref){
        var data = ref.data;
return [_vm._v("\n      "+_vm._s(_vm.$dayjs(data).format('	DD.MM.YYYY'))+"\n    ")]}},{key:"cell(updated_at)",fn:function(ref){
        var data = ref.data;
return [_vm._v("\n      "+_vm._s(_vm.$dayjs(data).format('	DD.MM.YYYY'))+"\n    ")]}}])}),_vm._v(" "),_c('paginations',{staticClass:"company-users--pagination",attrs:{"page":_vm.sort.page,"offset":[10, 25, 50],"pages":_vm.pages,"perPage":_vm.sort.per_page,"total":_vm.total,"count-items":_vm.countItems,"type":"right"},on:{"click":_vm.onSelectPage,"select:offset":_vm.selectOffset}}),_vm._v(" "),_c('add-ticket-modal',{ref:'modal',attrs:{"types":_vm.ticketTypesModal},on:{"update":_vm.fetchTickets}}),_vm._v(" "),_c('ticket-table-detail-info',{ref:'modalDetailsInfoTicket',attrs:{"ticketStatuses":_vm.ticketStatusesCalendar,"ticketTypes":_vm.ticketTypes,"ticketPreview":_vm.ticketPreviewUrl}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { validationMixin } from 'vuelidate'
import { helpers, required, minLength, maxLength, email } from 'vuelidate/lib/validators'
import { IS_MOBILE } from '~/store/device/type.device'
import { GET_USER } from '~/store/user/types.user'
const wrongFormat = helpers.regex('text', /^[a-zA-ZąćęłńóśźżĄĆĘŁŃÓŚŹŻ\-'\s]*$/)
const emailValidationText = helpers.regex('email', /^[a-zA-Z0-9._@\s]*$/)
const containsPasswordRequirement = helpers.regex('The password requires an uppercase, lowercase, number', /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])/)
export default {
  components: {
    UpdateUserFormModal: () =>
      import('~/components/modules/ModalWindows/UpdateUserFormModal.vue'),
  },
  mixins: [validationMixin],
  layout: 'profile',
  middleware: ['auth'],
  data() {
    return {
      subscribe: false,
      type: 'contacts',
      loading: false,
      form: {
        contacts: {
          first_name: '',
          last_name: '',
          phone: '',
          email: '',
        },
        password: {
          old_password: '',
          new_password: '',
        },
      },
    }
  },
  validations: {
    form: {
      contacts: {
        first_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        last_name: { required, minLength: minLength(2), maxLength: maxLength(32), wrongFormat },
        email: { required, email, minLength: minLength(5), maxLength: maxLength(64), emailValidationText },
        phone: { required, minLength: minLength(10) },
      },
      password: {
        old_password: { required, minLength: minLength(6), maxLength: maxLength(36), containsPasswordRequirement },
        new_password: { required, minLength: minLength(6), maxLength: maxLength(36), containsPasswordRequirement },
      },
    },
  },
  computed: {
    user() {
      return this.$store.getters[`user/${GET_USER}`]
    },
    userName() {
      if (!this.user) return ''
      return `${this.user.first_name} ${this.user.last_name}`
    },
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
  },
  watch: {
    user() {
      this.subscribe = this.user?.subscribe
    },
  },
  mounted() {
    this.subscribe = this.user?.subscribe
  },
  methods: {
    onOpenModal(type) {
      this.type = type
      const obj = {}
      obj.type = type
      switch (type) {
      case 'contacts':
        obj.title = this.$t('editCredentials')
        this.form[type] = {
          first_name: this.user.first_name,
          last_name: this.user.last_name,
          phone: this.user.phone,
          email: this.user.email,
        }
        break
      case 'password':
        obj.title = this.$t('changePassword')
        this.form[type] = {
          old_password: '',
          new_password: '',
        }
        break
      default:
        break
      }
      this.$refs.modal.handleClick(obj)
    },
    onChange(prop, val) {
      this.form[this.type][prop] = val
    },
    onSaveSettings() {
      this.loading = true
      const params = {
        subscribe: this.subscribe,
      }
      return this.$api.user
        .updateSettings(params)
        .finally(() => (this.loading = false))
    },
    connectGoogle() {

      try {
        window.location.href = `${this.$config.apiURL}/${this.$i18n.locale}/oauth/google-redirect`
        // this.$api.auth.сancelGoogleСonnection()
      } catch (error) {
        // console.log(error)
      }
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalOrderRefund from '~/components/modules/ModalWindows/profile/order/ModalOrderRefund.vue'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
export default {
  components: {
    Breadcrumbs,
    ModalOrderRefund,
  },
  layout: 'profile',
  middleware: ['auth'],
  async asyncData({ $api, store, localePath, route }) {
    store.commit('bc/SET_BC', [
      {name: 'Orders', slug: '/profile/orders'},
      { name: 'Order', slug: localePath({ name: 'Order' }) },
    ])
    try {
      const gerOrder = await $api.orders.getOrder(encodeURIComponent(encodeURIComponent(route.params.order)))
      const getPaymentMethod = await $api.cart.getPaymentMethod()
      const getDeliveryMethod = await $api.cart.getDeliveryMethod()
      return {
        order: gerOrder.data,
        paymentMethods: getPaymentMethod.data,
        deliveryMethods: getDeliveryMethod.data,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      order: null,
      paymentMethods: null,
      deliveryMethods: null,
      orderTypes: [this.$t('standard'), this.$t('dropshipping')],
      paymentStatuses: [this.$t('waiting'), this.$t('onTime'), this.$t('partial'), this.$t('onDelivery')],
      statuses: [this.$t('new'), this.$t('pending'), this.$t('accepted'), this.$t('closed'), this.$t('canceled'), this.$t('refunded')],
    }
  },
  computed: {
    getDeliveryMethod() {
      return this.deliveryMethods.find(method => method.id === this.order.delivery_method_id)
    },
    totalVat() {
      return Math.abs(this.order.total_netto - this.order.total_brutto)
    },
    totalOrder() {
      return this.order.total_brutto + this.order.payment_tax + this.order.delivery_cost
    },
  },
  methods: {
    openRefund() {
      this.$refs.orderModal.onOpen()
    },
  },
}

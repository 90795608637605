//
//
//
//
//
//
//
//
//
//
//
//

import Tabs from '~/components/UI/Tabs.vue'
import CompanyInfo from '~/components/pages/Profile/company/CompanyInfo.vue'
export default {
  components: {
    Tabs,
    CompanyInfo,
    CompanyAddress: () =>
      import('~/components/pages/Profile/company/CompanyAddress.vue'),
    CompanyUsers: () =>
      import('~/components/pages/Profile/company/CompanyUsers.vue'),
  },
  layout: 'profile',
  middleware: ['auth'],
  data() {
    return {
      tabs: [
        {
          id: 'CompanyInfo',
          text: this.$t('companyInfo'),
          isActive: true,
        },
        {
          id: 'CompanyUsers',
          text: this.$t('users'),
          isActive: false,
        },
        {
          id: 'CompanyAddress',
          text: this.$t('deliveryAddresses'),
          isActive: false,
        },
      ],
    }
  },
  computed: {
    componentName() {
      return this.tabs.find((t) => t.isActive).id
    },
  },
}

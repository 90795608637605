//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Rating from '~/components/UI/Rating.vue'
import Paginations from '~/components/UI/Paginations.vue'
import AddReviewModal from '~/components/modules/ModalWindows/profile/reviews/AddReviewModal.vue'
import WatchReviewModal from '~/components/modules/ModalWindows/profile/reviews/WatchReviewModal.vue'
import { IS_MOBILE } from '~/store/device/type.device'
export default {
  components: {
    AddReviewModal,
    Paginations,
    Rating,
    WatchReviewModal,
  },
  layout: 'profile',
  middleware: ['auth'],
  data() {
    return {
      reviews: [],
      reviewStatuses:[
        {key: 0, status: this.$t('pending')},
        {key: 1, status: this.$t('published')},
        {key: 10, status: this.$t('declined')},
      ],
      total: 0,
      pages: 0,
      sort: {
        per_page: 10,
        page: 1,
      },
      selectPage: 1,
      styleStar: {
        padding: 13,
        size: 13,
        cursor: 'default',
      },
      countItems: 0,
    }
  },
  fetch() {
    return this.fetchReviews()
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
  },
  methods: {
    async fetchReviews() {
      const payload = {
        per_page: this.sort.per_page,
        page: this.sort.page,
      }
      try {
        const response = await this.$api.reviews.getProfileReviews(payload)
        const { data, meta } = response
        this.reviews = data
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      } catch (error) {
        // console.log(error)
      }
    },
    selectOffset(value) {
      this.sort.per_page = value
    },
    onSelectPage(value) {
      this.sort.page = value
      this.fetchReviews()
    },
    getReviewStatus(value) {
      return this.reviewStatuses.find(item => item.key === Number(value)).status
    },
    openModalReview(item, stars) {
      if(this.textButton(stars) === this.$t('writeAReview')) {
        this.$refs.addReviewModal.onOpen(item)
      } else {
        this.$refs.watchReviewModal.onOpen(item)
      }
    },
    textButton(stars) {
      return stars !== null ? this.$t('watchReview') : this.$t('writeAReview')
    },
    typeButton(stars) {
      return stars !== null ? 'secondary' : 'primary'
    },
  },
}

//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ModalEditComment from '~/components/modules/ModalWindows/profile/checkout/ModalEditComment.vue'
import Breadcrumbs from '~/components/UI/Breadcrumbs.vue'
import Table from '~/components/UI/Table.vue'
import getDeviceType from '~/mixins/getDeviceType'
export default {
  name: 'RefundInfo',
  components: {
    Breadcrumbs,
    Table,
    ModalEditComment,
  },
  mixins: [getDeviceType],
  layout: 'profile',
  middleware: ['auth'],
  async asyncData({ $api, store, localePath, route }) {
    store.commit('bc/SET_BC', [
      {name: 'Refunds', slug: '/profile/refunds'},
      { name: 'Refund', slug: localePath({ name: 'Refund' }) },
    ])
    try {
      const getRefund = await $api.refunds.getRefund(route.params.refund)
      const getOrder = await $api.orders.getOrder(encodeURIComponent(encodeURIComponent(getRefund.data.order_number)))
      const getDeliveryMethod = await $api.cart.getDeliveryMethod()

      const refundMap = {
        ...getRefund.data,
        refund_products: getRefund.data.refund_products.map((item) => {
          return {
            ...item,
            main_info: {
              files: item.files,
              name: item.name,
            },
            total_price: item.price_netto * item.quantity,
            total_vat: item.price_vat * item.quantity,
            total_price_vat: (item.price_netto * item.quantity) + (item.price_vat * item.quantity),
          }
        }),
      }

      return {
        refund: refundMap,
        order: getOrder.data,
        deliveryMethods: getDeliveryMethod.data,
      }
    } catch (error) {
      // console.log(error)
    }
  },
  data() {
    return {
      refund: null,
      orderTypes: ['Standard', 'Dropshipping'],
      paymentStatuses: [this.$t('waiting'), this.$t('onTime'), this.$t('partial'), this.$t('paid'), this.$t('onDelivery')],
      statuses: [this.$t('pending'), this.$t('accepted'), this.$t('sentToStock'), this.$t('arrivedToStock'), this.$t('returnedToClient'), this.$t('denied'), this.$t('refunded')],
    }
  },
  computed: {
    fields() {
      return [
        {
          key: 'main_info',
          label: this.$t('product'),
          width: '200px',
        },
        {
          key: 'price_netto',
          label: this.$t('pricePerItem'),
          width: '140px',
        },
        {
          key: 'quantity',
          label: this.$t('qty'),
          width: '100px',
        },
        {
          key: 'total_price',
          label: this.$t('totalPrice'),
          width: '50px',
        },
      ]
    },
    getDeliveryMethod() {
      return this.deliveryMethods.find(method => method.id === this.order.delivery_method_id)
    },
    getTotalAmount() {
      return Math.round(this.refund.refund_products.map((item) => item.total_price_vat ).reduce((total, value) => total + value))
    },
    getTotalVat() {
      return this.refund.refund_products.map((item) => item.total_vat ).reduce((total, value) => total + value)
    },
    totalRefund() {
      return this.getDeliveryMethod.value + this.getTotalAmount + this.getTotalVat
    },
  },
  methods: {
    editComment() {
      this.$refs.modalEditComment.onOpen(this.refund.comment)
    },
    async onChangeComment(value) {
      this.refund.comment = value
      const payload = {
        comment: value,
      }
      await this.$api.refunds.editCommentRefund(this.$route.params.refund, payload)
    },
  },
}

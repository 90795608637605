//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paginations from '~/components/UI/Paginations.vue'
import { IS_MOBILE } from '~/store/device/type.device'
export default {
  components: {
    Paginations,
  },
  layout: 'profile',
  middleware: ['auth'],
  async asyncData({ $api }) {
    const payload = {
      per_page: 10,
      page: 1,
    }
    try {
      const responseLoyalty = await $api.loyalty.getLoyaltyProgram()
      const responseHistory = await $api.loyalty.getLoyaltyHistory(payload)
      const { data, meta } = responseHistory
      return {
        loyalty: responseLoyalty.data,
        history: data,
        total: meta.pagination.total,
        pages: meta.pagination.total_pages,
        countItems: meta.pagination.count,
      }
    } catch (e) {
      // error({ statusCode: 404, message: 'Page not found' })
    }
  },
  data() {
    return {
      history: [],
      loyalty: {},
      total: 0,
      pages: 0,
      sort: {
        per_page: 10,
        page: 1,
      },
      selectPage: 1,
      countItems: 0,
    }
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
  },
  methods: {
    async fetchLoyalty() {
      const payload = {
        per_page: this.sort.per_page,
        page: this.sort.page,
      }
      try {
        const response = await this.$api.loyalty.getLoyaltyHistory(payload)
        const { data, meta } = response
        this.history = data
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      } catch (error) {
        // console.log(error)
      }
    },
    selectOffset(value) {
      this.sort.per_page = value
    },
    onSelectPage(value) {
      this.sort.page = value
      this.fetchLoyalty()
    },
    typeSymbol(type) {
      return type === 1 ? '+' : '-'
    },
  },
}

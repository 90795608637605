var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"favorites"},[_c('h1',{staticClass:"title headline size-profile fw-700"},[_vm._v(_vm._s(_vm.$t('favorites')))]),_vm._v(" "),(!_vm.favorites.length)?_c('div',{staticClass:"favorites__no-favorites"},[_vm._m(0),_vm._v(" "),_c('div',{staticClass:"favorites__info"},[_c('h2',{staticClass:"favorites__info-title"},[_vm._v(_vm._s(_vm.$t('somethingCatchYourEye')))]),_vm._v(" "),_c('p',{staticClass:"favorites__info-text"},[_vm._v(_vm._s(_vm.$t('saveToFavorites'))),_c('br'),_vm._v(" "+_vm._s(_vm.$t('toFavorites'))+".")]),_vm._v(" "),_c('btn',{staticClass:"button--primary favorites__info-btn",attrs:{"to":_vm.localePath('/product/catalog'),"max-width":'312px',"height":'48px',"font-size":'18px'}},[_vm._v("\n        "+_vm._s(_vm.$t('goToCatalog'))+"\n        "),_c('icon',{staticClass:"favorites__info-btn-icon",attrs:{"icon":"shop","w":'22px',"h":'20px',"fill":"none"}})],1)],1)]):_vm._e(),_vm._v(" "),(_vm.favorites.length)?_c('table',{staticClass:"favorites__table"},[_c('thead',{staticClass:"favorites__table-header"},[_c('tr',{staticClass:"favorites__table-row"},[_c('th',{staticClass:"favorites__table-title"},[_vm._v("\n          "+_vm._s(_vm.$t('product'))+"\n        ")]),_vm._v(" "),_c('th',{staticClass:"favorites__table-title"},[_vm._v("\n          "+_vm._s(_vm.$t('pricePerItem'))+"\n        ")]),_vm._v(" "),_c('th',{staticClass:"favorites__table-title"},[_c('span',[_vm._v(_vm._s(_vm.$t('qty')))]),_vm._v("\n          "+_vm._s(_vm.$t('actions'))+"\n        ")])])]),_vm._v(" "),_c('tbody',{staticClass:"favorites__table-body"},_vm._l((_vm.favorites),function(product,index){return _c('tr',{key:index,staticClass:"favorites__table-product"},[_c('td',{class:[
            'favorites__table-product-info',
            { 'favorites__table-product--not-available': product.item.status === 2 }
          ]},[_c('div',{staticClass:"favorites__table-product-image"},[(product.item.files.length)?_c('img',{attrs:{"src":((_vm.$config.baseURL) + "/files" + (product.item.files[0].url)),"alt":"favorites"}}):_c('div',{staticClass:"media-default"},[_c('icon',{staticClass:"media-icon",attrs:{"icon":"picture","w":"100%","h":"auto"}})],1)]),_vm._v(" "),_c('div',{staticClass:"favorites__table-product-info--inner"},[_c('p',{staticClass:"favorites__table-product-name"},[_vm._v("\n              "+_vm._s(product.item.name)+"\n            ")]),_vm._v(" "),_c('div',{staticClass:"favorites__table-product-code"},[_c('p',{staticClass:"favorites__table-product-code-text"},[_vm._v("\n                Code:\n              ")]),_vm._v(" "),_c('div',{staticClass:"favorites__table-product-code-number"},[_vm._v("\n                "+_vm._s(product.item.code)+"\n              ")])]),_vm._v(" "),_c('product-price',{staticClass:"favorites__table-product-price-mobile",attrs:{"price":product.item.price_type,"visible-discount":false,"font-size-price-left":'14px',"font-size-price-right":'11px',"font-size-price-bottom":'11px'}})],1)]),_vm._v(" "),_c('td',{staticClass:"favorites__table-product-price-desktop"},[_c('product-price',{attrs:{"price":product.item.price_type,"visible-discount":false,"font-size-price-left":'14px',"font-size-price-right":'11px',"font-size-price-bottom":'11px'}})],1),_vm._v(" "),_c('td',{staticClass:"favorites__table-product-event"},[_c('div',{staticClass:"favorites__table-product-event--inner"},[(
                (!!Number(product.item.price_type.brutto) || !!Number(product.item.price_type.netto)) &&
                product.item.status === 1 && !product.count
              )?_c('counter',{staticClass:"favorites__table-product-counter",attrs:{"value":product.cart_quantity,"height":'40px',"width":_vm.isMobile ? '75px' : '38px',"width-count":_vm.isMobile ? '90px' : '44px',"disabled-dec":product.item.slug === _vm.selectProduct || product.cart_quantity === 1,"disabled-inc":product.item.slug === _vm.selectProduct},on:{"click:dec":function($event){return _vm.decreaseItemCart(index)},"click:inc":function($event){return _vm.increaseItem(index)}}}):_vm._e(),_vm._v(" "),(
                (!!Number(product.item.price_type.brutto) ||
                !!Number(product.item.price_type.netto)) &&
                product.item.status === 1
              )?_c('btn',{staticClass:"button--primary",attrs:{"disabled":!!product.count,"fontSize":_vm.isMobile ? '14px' : '18px'},on:{"click":function($event){return _vm.addProductToCart(product, product.entity, index)}}},[_vm._v("\n              "+_vm._s(product.cart_quantity ? _vm.$t('itemInCart') : _vm.$t('addToCart'))+"\n              "),_c('icon',{staticClass:"favorites__table-product-event-basket-icon",attrs:{"icon":'basket',"w":'20px',"h":'20px',"stroke":_vm.$color.white,"fill":"none"}})],1):_vm._e(),_vm._v(" "),(_vm.notifyMeWhenAvailableWisible(product))?_c('btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.textTicket,
                show: _vm.textTicket && _vm.selectProduct === product.item.slug
              }),expression:"{\n                content: textTicket,\n                show: textTicket && selectProduct === product.item.slug\n              }"}],attrs:{"type":'secondary',"color":_vm.$color.dark,"fontSize":_vm.isMobile ? '14px' : '18px'},on:{"click":function($event){return _vm.createTicket(product, 1)}}},[_vm._v("\n              "+_vm._s(_vm.$t('notifyMeWhenAvailable'))+"\n              "),_c('icon',{staticClass:"favorites__info-btn-icon",attrs:{"icon":'mail',"w":'22px',"h":'22px',"stroke":_vm.$color.dark,"fill":"none"}})],1):_vm._e(),_vm._v(" "),(_vm.preorderWisible(product))?_c('btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.textTicket,
                show: _vm.textTicket && _vm.selectProduct === product.item.slug
              }),expression:"{\n                content: textTicket,\n                show: textTicket && selectProduct === product.item.slug\n              }"}],staticClass:"button--primary",attrs:{"fontSize":_vm.isMobile ? '14px' : '18px'},on:{"click":function($event){return _vm.createTicket(product, 6)}}},[_vm._v("\n              "+_vm._s(_vm.$t('preorder'))+"\n              "),_c('icon',{staticClass:"favorites__table-product-event-basket-icon",attrs:{"icon":'preorder',"w":_vm.isMobile ? '14px' : '18px',"h":'17px',"stroke":_vm.$color.white,"fill":"none"}})],1):_vm._e(),_vm._v(" "),(_vm.requestThePriceWisible(product))?_c('btn',{directives:[{name:"tooltip",rawName:"v-tooltip",value:({
                content: _vm.textTicket,
                show: _vm.textTicket && _vm.selectProduct === product.item.slug
              }),expression:"{\n                content: textTicket,\n                show: textTicket && selectProduct === product.item.slug\n              }"}],staticClass:"button--secondary",attrs:{"color":_vm.$color.dark,"fontSize":_vm.isMobile ? '14px' : '18px'},on:{"click":function($event){return _vm.createTicket(product, 2)}}},[_vm._v("\n              "+_vm._s(_vm.$t('requestThePrice'))+"\n              "),_c('icon',{staticClass:"favorites__info-btn-icon",attrs:{"icon":'question-answer',"w":'22px',"h":'23px',"stroke":_vm.$color.black,"fill":"none"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"favorites__table-product-event--remove-desktop",on:{"click":function($event){return _vm.deleteFavorite(product.id)}}},[_c('icon',{staticClass:"icon",attrs:{"w":'22px',"h":'20px',"icon":"remove","stroke":_vm.$color.grey}})],1),_vm._v(" "),_c('btn',{staticClass:"favorites__table-product-event--remove-mobile",attrs:{"color":_vm.$color.dark,"fontSize":_vm.isMobile ? '14px' : '18px',"type":'light-grey'},on:{"click":function($event){return _vm.deleteFavorite(product.id)}}},[_vm._v("\n              Remove from favorites\n              "),_c('icon',{staticClass:"icon",attrs:{"icon":'remove',"w":'22px',"h":'20px',"stroke":_vm.$color.grey,"fill":"none"}})],1)],1)])])}),0)]):_vm._e(),_vm._v(" "),(_vm.favorites.length)?_c('paginations',{attrs:{"page":_vm.sort.page,"offset":[10, 25, 50],"pages":_vm.pages,"perPage":_vm.sort.per_page,"total":_vm.total,"count-items":_vm.countItems,"type":"right"},on:{"click":_vm.onSelectPage,"select:offset":_vm.selectOffset}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"favorites__image"},[_c('img',{attrs:{"src":require("@/assets/images/favorites.png"),"alt":"favorites"}})])}]

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"refunds"},[_c('h1',{staticClass:"title headline size-profile fw-700"},[_vm._v(_vm._s(_vm.$t('refunds')))]),_vm._v(" "),_c('Table',{staticClass:"refunds__table",attrs:{"fields":_vm.fields,"items":_vm.refunds,"table-black":true,"horizontal-padding-body-column":_vm.isMobile || _vm.isTablet ? '0' : _vm.isLaptop ? '6px' : _vm.isLargeTable ? '6px' : '12px',"vertical-padding-body-column":_vm.isMobile || _vm.isTablet ? '0' : _vm.isLaptop ? '10px' : _vm.isLargeTable ? '10px' : '16px'},on:{"click:head-item":_vm.onSort,"click:body-item":function($event){return _vm.onClickTableItem($event, 'Edit')}},scopedSlots:_vm._u([{key:"cell(status)",fn:function(ref){
var data = ref.data;
return [_c('span',{class:[
          'status pointer',
          ("status--" + data)
        ]},[_vm._v("\n        "+_vm._s(_vm.statuses[data])+"\n      ")])]}},{key:"cell(total_amount)",fn:function(ref){
        var data = ref.data;
return [_vm._v("\n      "+_vm._s(data)+" zl\n    ")]}},{key:"cell(created_at)",fn:function(ref){
        var data = ref.data;
return [_vm._v("\n      "+_vm._s(_vm.$dayjs(data).format('	DD.MM.YYYY'))+"\n    ")]}},{key:"cell(receiver)",fn:function(ref){
        var data = ref.data;
return [_c('p',{staticClass:"refunds__table-item-name ellipsis"},[_vm._v(_vm._s(data))])]}},{key:"cell(action)",fn:function(){return [_c('btn',{staticClass:"button--secondary",attrs:{"max-width":'141px',"height":'32px'}},[_vm._v("\n        "+_vm._s(_vm.$t('viewRefund'))+"\n      ")])]},proxy:true}])}),_vm._v(" "),_c('paginations',{staticClass:"refunds--pagination",attrs:{"page":_vm.sort.page,"offset":[10, 25, 50],"pages":_vm.pages,"perPage":_vm.sort.per_page,"total":_vm.total,"count-items":_vm.countItems,"type":"right"},on:{"click":_vm.onSelectPage,"select:offset":_vm.selectOffset}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
import { render, staticRenderFns } from "./promocodes.vue?vue&type=template&id=ca1cacc2&scoped=true&"
import script from "./promocodes.vue?vue&type=script&lang=js&"
export * from "./promocodes.vue?vue&type=script&lang=js&"
import style0 from "./promocodes.vue?vue&type=style&index=0&id=ca1cacc2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ca1cacc2",
  null
  
)

export default component.exports
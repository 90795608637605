//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AddTicketModal from '~/components/modules/ModalWindows/profile/tickets/AddTicketModal.vue'
import TicketTableDetailInfo from '~/components/modules/ModalWindows/profile/tickets/TicketTableDetailInfo.vue'
import Table from '~/components/UI/Table.vue'
import Paginations from '~/components/UI/Paginations.vue'
import { IS_MOBILE } from '~/store/device/type.device'
export default {
  components: {
    Table,
    Paginations,
    AddTicketModal,
    TicketTableDetailInfo,
  },
  layout: 'profile',
  middleware: ['auth'],
  data() {
    return {
      tickets: [],
      fields: [
        {
          key: 'ticket_number',
          label: this.$t('ticketNumber'),
          sortable: true,
          width: '120px',
        },
        {
          key: 'status',
          label: this.$t('status'),
          sortable: true,
          width: 'auto',
        },
        {
          key: 'email',
          label: this.$t('labelEmail'),
          sortable: true,
          width: '175px',
        },
        {
          key: 'first_name',
          label: this.$t('name'),
          sortable: true,
          width: '99px',
        },
        {
          key: 'type',
          label: this.$t('ticketType'),
          sortable: true,
          width: '174px',
        },
        {
          key: 'phone',
          label: this.$t('phoneNumber'),
          sortable: true,
          width: '160px',
        },
        {
          key: 'product_name',
          label: this.$t('productName'),
          sortable: true,
          width: '100px',
        },
        {
          key: 'created_at',
          label: this.$t('ticketCreated'),
          sortable: true,
          width: '30px',
        },
        {
          key: 'updated_at',
          label: this.$t('updateDate'),
          sortable: true,
          width: '30px',
        },
      ],
      ticketStatusesCalendar: [this.$t('new'), this.$t('inProgress'), this.$t('closed')],
      ticketTypes: [
        {
          type: 'Notify available',
          key: 1,
        },
        {
          type: 'Request price',
          key: 2,
        },
        {
          type: 'Order problem',
          key: 3,
        },
        {
          type: 'Refund problem',
          key: 4,
        },
        {
          type: 'Other problem',
          key: 5,
        },
        {
          type: 'Preorder',
          key: 6,
        },
      ],
      ticketTypesModal: [{key: 3, type: 'Order Problem'}, {key: 4 , type: 'Refund Problem'}, {key: 5, type: 'Other Problem'}],
      total: 0,
      pages: 0,
      sort: {
        orderBy: {},
        per_page: 10,
        page: 1,
      },
      selectPage: 1,
      ticket: {},
      ticketPreviewUrl: null,
      countItems: 0,
    }
  },
  fetch() {
    return this.fetchTickets()
  },
  computed: {
    isMobile() {
      return this.$store.getters[`device/${IS_MOBILE}`]
    },
  },
  methods: {
    fetchTickets() {
      const params = {
        orderBy: this.sort.orderBy,
        per_page: this.sort.per_page,
        page: this.sort.page,
      }
      return this.$api.tickets.getTickets(params).then((payload) => {
        const { data, meta } = payload
        const tickets = data.map(item => ({
          ...item,
          product_name: item.product_name ? item.product_name : item.set_name,

        }))
        this.tickets = tickets
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      })
    },
    onSort(value) {
      this.sort.orderBy = {
        [value.key]:
          this.sort.orderBy[value.key] &&
          this.sort.orderBy[value.key] === 'DESC'
            ? 'ASC'
            : 'DESC',
      }
      this.fetchTickets()
    },
    getTypeTicket(type) {
      return this.ticketTypes.find((item) => item.key === type).type
    },
    createTicket() {
      this.$refs.modal.handleClick()
    },
    async onClickTableItem(item) {
      return await this.$api.tickets.getTicket(item.id).then((payload) => {
        const { data } = payload
        this.ticket = data
        if(this.ticket.product) {this.ticketPreviewUrl = this.ticket.product.file_preview_url}
        if(this.ticket.set) this.ticketPreviewUrl = this.ticket.set.file_preview_url
        this.$refs.modalDetailsInfoTicket.onOpen(item)
      })

    },
    selectOffset(value) {
      this.sort.per_page = value
    },

    onSelectPage(value) {
      this.sort.page = value
      this.fetchTickets()
    },
  },
}

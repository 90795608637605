//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import Paginations from '~/components/UI/Paginations.vue'
export default {
  components: {
    Paginations,
  },
  layout: 'profile',
  middleware: ['auth'],
  data() {
    return {
      promo_codes: [],
      total: 0,
      pages: 0,
      sort: {
        per_page: 12,
        page: 1,
      },
      selectPage: 1,
      countItems: 0,
    }
  },
  fetch() {
    return this.fetchPromoCodes()
  },
  methods: {
    async fetchPromoCodes() {
      const payload = {
        per_page: this.sort.per_page,
        page: this.sort.page,
      }

      try {
        const response = await this.$api.promoCodes.getPromoCodes(payload)
        const { data, meta } = response
        this.promo_codes = data
        this.total = meta.pagination.total
        this.pages = meta.pagination.total_pages
        this.countItems = meta.pagination.count
      } catch (error) {
        // console.log(error)
      }
    },
    selectOffset(value) {
      this.sort.per_page = value
    },

    onSelectPage(value) {
      this.sort.page = value
      this.fetchPromoCodes()
    },
    saleValue(type, value) {
      let saleValue = null
      switch (type) {
      case 1:
        saleValue = `-${value}%`
        break
      case 2:
        saleValue = `-${value} zl`
        break
      case 3:
        saleValue = this.$t('freeDelivery')
        break
      }
      return saleValue
    },
    usagesLeft(limit, usagesLeft) {
      return limit !== 0 ? usagesLeft : this.$t('unlimited')
    },
  },
}
